import axios from "axios";
import Toastr from "toastr";
import router from "@/routes";
import "toastr/build/toastr.css";
const url = process.env.VUE_APP_API_BASE_URL;

const actions = {
  async getHomeData(store, params) {
    axios
      .get(`${url}wp/v2/get_home_data`, { params })
      .then((resp) => {
        store.commit("SET_HOMEBODYCONTENT", resp.data);
      })
      .catch((err) => {
        // Toastr.handleServerError(err);
        console.log(err);
      });
  },

  async getAboutData(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/get_about_page_data`, { params })
        .then((resp) => {
          store.commit("SET_ABOUTUSCONTENT", resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async getProjectCategoryData(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/get_case_study_category_list`, { params })
        .then((resp) => {
          store.commit("SET_PROJECTCATEGORYCONTENT", resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async getProjectListData2(store, params) {
    axios
      .post(`${url}wp/v2/get_case_study_data`, params)
      .then((resp) => {
        store.commit("SET_PROJECTLISTCONTENT", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getContactUsData(store, params) {
    axios
      .get(`${url}wp/v2/get_contact_us_page_data`, { params })
      .then((resp) => {
        store.commit("SET_CONTACTUSCONTENT", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getproductslist(store, params) {
    axios
      .get(`${url}wp/v2/FilterProductList`, { params })
      .then((resp) => {
        console.log(resp);
        store.commit("SET_PRODUCTNOOFPAGE", resp.data.totalpage);
        store.commit("SET_PRODUCTLIST", resp.data.data);
        store.commit("SET_PRODUCTLISTTYPE", resp.data.list_type);
        store.commit("SET_PCATELIST", resp.data.cate_list);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getproductslistDefult(store, params) {
    axios
      .get(`${url}wp/v2/product?_embed`, { params })
      .then((resp) => {
        store.commit(
          "SET_PRODUCTNOOFPAGE",
          parseInt(resp.headers.get("X-WP-TotalPages"))
        );
        store.commit("SET_PRODUCTLIST", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getProjectListData(store, params) {
    axios
      .get(`${url}wp/v2/project?_embed`, { params })
      .then((resp) => {
        store.commit(
          "SET_TOTALPROJECTPAGE",
          parseInt(resp.headers.get("X-WP-TotalPages"))
        );
        store.commit("SET_PROJECTLISTCONTENT", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetproductDetails(store, params) {
    axios
      .get(`${url}wp/v2/get_product_details_data`, { params })
      .then((resp) => {
        console.log(resp.data);
        store.commit("SET_PRODUCTDETAILS", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetAstrowaveProductDetails(store, params) {
    axios
      .get(`${url}wp/v2/get_astrowave_product_details_data`, { params })
      .then((resp) => {
        console.log(resp.data);
        store.commit("SET_ASTROWAVEPRODUCTDETAILS", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetFamilyDetails(store, params) {
    axios
      .get(`${url}wp/v2/family_page_details`, { params })
      .then((resp) => {
        console.log(resp.data);
        store.commit("SET_FAMILYDETAILS", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetLatestProject(store, params) {
    axios
      .get(`${url}wp/v2/getletestprojects`, { params })
      .then((resp) => {
        store.commit("SET_LATESTPROJECT", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GeneralInformation(store, params) {
    axios
      .get(`${url}wp/v2/GeneralInformationval`, { params })
      .then((resp) => {
        store.commit("SET_GENERALINFORMATION", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getDownloadCategory(store, params) {
    axios
      .get(`${url}wp/v2/GetDownloadCategory`, { params })
      .then((resp) => {
        store.commit("SET_DOWNLOADCATEGORYLIST", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetDownloadFileslist(store, params) {
    axios
      .get(`${url}wp/v2/GetDownloadFileslist`, { params })
      .then((resp) => {
        store.commit("SET_DOWNLOADFILESLIST", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetProjectDetails(store, params) {
    axios
      .get(`${url}wp/v2/get_case_study_details_data`, { params })
      .then((resp) => {
        store.commit("SET_PROJECTDETAILS", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetDownloadPageData(store, params) {
    axios
      .get(`${url}wp/v2/GetDownloadPageData`, { params })
      .then((resp) => {
        console.log("DOWNLOADFILE ", resp.data);
        store.commit("SET_DOWNLOADPAGEDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getproductfilteroptions(store, params) {
    axios
      .get(`${url}wp/v2/getproductfilteroptions`, { params })
      .then((resp) => {
        store.commit("SET_PRODUCTFILTERDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetVaritionproductFilteroptions(store, params) {
    axios
      .get(`${url}wp/v2/GetVariableProductFilterOptions`, { params })
      .then((resp) => {
        store.commit("SET_VARIATIONPRODUCTFILTEROPTION", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async VariationProductList(store, params) {
    axios
      .get(`${url}wp/v2/GetVariableProductList`, { params })
      .then((resp) => {
        store.commit("SET_VARIATIONPRODUCT", resp.data.listdata);
        store.commit("SET_VARIATIONPRODUCTTOTALPAGE", resp.data.totalpage);
        store.commit("SET_TOTALVARIATIONPRODUCT", resp.data.no_of_post);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetproductDownloadDetaSheet(store, params) {
    axios
      .get(`${url}wp/v2/Variation_Product_download`, { params })
      .then((resp) => {
        const link = document.createElement("a");
        link.href = resp.data.url;
        link.setAttribute("download", resp.data.filename);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetproductDownloadDetaSheetTwo(store, params) {
    axios
      .get(`${url}wp/v2/GetCustomiseSvgfile`, { params })
      .then((resp) => {
        const link = document.createElement("a");
        link.href = resp.data.specificationpdf.url;
        link.setAttribute("download", resp.data.specificationpdf.filename);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetVariableproductDetaSheetDetails(store, params) {
    axios
      .get(`${url}wp/v2/Variation_Product_DataSheet`, { params })
      .then((resp) => {
        store.commit("SET_VARIATION_PRODUCT_DATASHEET_DETAILS", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getNewsListData(store, params) {
    axios
      .get(`${url}wp/v2/news?_embed`, { params })
      .then((resp) => {
        store.commit(
          "SET_TOTALNEWSPAGE",
          parseInt(resp.headers.get("X-WP-TotalPages"))
        );
        store.commit("SET_NEWSLISTCONTENT", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetNewsDetails(store, params) {
    axios
      .post(`${url}wp/v2/get_news_details_page_data`, params)
      .then((resp) => {
        store.commit("SET_NEWSDETAILS", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getSustainabilityData(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/sustainability_page_data`, { params })
        .then((resp) => {
          store.commit("SET_SUSTAINABILITYCONTENT", resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async GetUserprojectList(store, params) {
    axios
      .get(`${url}wp/v2/Get_UserprojectList`, { params })
      .then((resp) => {
        store.commit("SET_MYPROJECTLIST", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async ProductAddtoProject(store, params) {
    axios
      .post(`${url}wp/v2/ProductAddtoProject`, params)
      .then((resp) => {
        console.log(params);
        Toastr.success(resp.data);
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async DowloaDdataProductSheet(store, params) {
    axios
      .get(`${url}wp/v2/Downloadzipfile`, { params })
      .then((resp) => {
        const link = document.createElement("a");
        link.href = resp.data.url;
        link.setAttribute("download", resp.data.filename);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async CreatNewProject(store, params) {
    axios
      .post(`${url}wp/v2/CreatNewProject`, params)
      .then((resp) => {
        Toastr.success(resp.data.message);
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async CreatNewProjectFromPage(store, params) {
    const promise = new Promise(function (resolve, reject) {
      axios
        .post(`${url}wp/v2/CreatNewProject`, params)
        .then((resp) => {
          if (resp.data.errorcode == "0") {
            Toastr.error(resp.data.message);
            router.push({ name: "DashboardMyAccount" });
          } else {
            Toastr.success(resp.data.message);
            router.push({ name: "DashboardMyAccount" });
          }
        })
        .catch((err) => {
          Toastr.error(err.response.message);
        });
    });

    promise.then(
      (successMessage) => {
        if (successMessage) {
          router.push({ name: "DashboardMyAccount" });
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  },

  async DeleteuserProject(store, params) {
    axios
      .get(`${url}wp/v2/DeleteuserProject`, { params })
      .then((resp) => {
        if (resp.data.messgecode == 1) Toastr.success(resp.data.message);
        else Toastr.error(resp.data.message);
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async getCustomLightingData(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/custom_lighting_page_data`, { params })
        .then((resp) => {
          store.commit("SET_CUSTOMLIGHTCONTENT", resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async getAntimicrobialData(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/antimicrobial_page_data`, { params })
        .then((resp) => {
          store.commit("SET_ANTIMICROBIALCONTENT", resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async getHumanCentricData(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/human_centric_lighting_page_data`, { params })
        .then((resp) => {
          store.commit("SET_HUMANCENTRICCONTENT", resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async SendQuationRequest(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/SendQuationRequest`, { params })
        .then((resp) => {
          store.commit("SET_REQUESTDISPLAYMESSAGE", resp.data);
          Toastr.success(resp.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async GetMyProjectdetails(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/GetMyProjectdetails`, { params })
        .then((resp) => {
          if (resp.data.errorcode == 200)
            store.commit("SET_MYPROJECTDETAILS", resp.data);
          else router.push({ name: "DashboardMyAccount" });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async UpdateMyProjectdetails(store, params) {
    const promise = new Promise(function (resolve, reject) {
      axios
        .post(`${url}wp/v2/UpdateMyProjectdetails`, params)
        .then((resp) => {
          store.commit("SET_REQUESTDISPLAYMESSAGE", resp.data);
          Toastr.success(resp.data.message);
        })
        .catch((err) => {
          Toastr.error(err.response.message);
        });
    });
  },

  async DownloadprojectSummarypdf(store, params) {
    axios
      .get(`${url}wp/v2/DownloadprojectSummarypdf`, { params })
      .then((resp) => {
        const link = document.createElement("a");
        link.href = resp.data.url;
        link.setAttribute("download", resp.data.filename);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async submitContactDetails(store, params) {
    axios
      .post(`${url}wp/v2/ContactMail`, params)
      .then((resp) => {
        //console.log('DOWNLOADFILE ',resp.data);
        Toastr.success(resp.data.message);
        router.push({ name: "ThankYou" });
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async getCasambiData(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/casambi_page_data`, { params })
        .then((resp) => {
          store.commit("SET_CASAMBICONTENT", resp.data);
          // console.log(resp.data)
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async getCommonPagesData(store, params) {
    axios
      .post(`${url}wp/v2/get_common_page_data_by_slug`, params)
      .then((resp) => {
        //console.log('DOWNLOADFILE ',resp.data);
        store.commit("SET_COMMONPAGECONTENT", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getSlaveryPagesData(store, params) {
    axios
      .get(`${url}wp/v2/slavery_page_data`, { params })
      .then((resp) => {
        //console.log('DOWNLOADFILE ',resp.data);
        store.commit("SET_SLAVERYPAGECONTENT", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async DownloadprojectExcelSummary(store, params) {
    // axios.get(`${url}wp/v2/DownloadprojectExcelSummary`, { params },{ responseType: 'blob'}).then((resp) => {
    //     const url = URL.createObjectURL(new Blob([resp.data]))
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.setAttribute('download', 'test.xlsx')
    //     document.body.appendChild(link)
    //     link.click()
    //     //store.commit('SET_HTMLTOPDFDATA', resp.data);
    //    console.log(resp.data)
    // }).catch((err) => {
    //     console.log(err);
    // });

    axios
      .get(`${url}wp/v2/DownloadprojectExcelSummary`, { params })
      .then((resp) => {
        const link = document.createElement("a");
        link.href = resp.data.file;
        link.setAttribute("download", resp.data.filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetproductlistBycategory(store, params) {
    axios
      .get(`${url}wp/v2/GetproductlistBycategory`, { params })
      .then((resp) => {
        store.commit("SET_PRODUCTLISTBYCATEGORY", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetProductVariantsByProductSlug(store, params) {
    axios
      .get(`${url}wp/v2/GetProductVariants`, { params })
      .then((resp) => {
        store.commit("SET_PRODUCTVARIATIONDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetProductdetailsBySlug(store, params) {
    axios
      .get(`${url}wp/v2/GetProductdetailsBySlug`, { params })
      .then((resp) => {
        console.log("variationproductdetails", resp.data);
        store.commit("SET_VARIATIONPRODUCTDETAILS", resp.data.productdetails);
        store.commit("SET_VARIATIONVARIABLE", resp.data.filtervalue);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetvariableProductdetails(store, params) {
    axios
      .post(`${url}wp/v2/GetvariableProductdetails`, params)
      .then((resp) => {
        console.log("DOWNLOADFILE ", resp.data.filtervalue);
        if (resp.data.errocode == 400)
          Toastr.error("Product is not available for selected combination");
        store.commit("SET_VARIATIONPRODUCTDETAILS", resp.data.productdetails);
        store.commit("SET_VARIATIONVARIABLE", resp.data.filtervalue);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetvariableProductdetails1222(store, params) {
    axios
      .get(`${url}wp/v2/GetvariableProductdetails`, { params })
      .then((resp) => {
        console.log("12344", resp.data);
        if (resp.data.errocode == 400)
          Toastr.error("Product is not available for selected combination");
        store.commit("SET_VARIATIONPRODUCTDETAILS", resp.data.productdetails);
        store.commit("SET_VARIATIONVARIABLE", resp.data.filtervalue);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async saveNewsLetter(store, params) {
    // console.log(params)
    axios
      .post(`${url}wp/v2/AddNewsLetter`, params)
      .then((resp) => {
        Toastr.success(resp.data.message);
        // console.log('Project data - ',resp.data);
        // store.commit('SET_PROJECTLISTCONTENT', resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetMadeInBritainData(store, params) {
    axios
      .get(`${url}wp/v2/made_in_britain_page_data`, { params })
      .then((resp) => {
        store.commit("SET_MADEINBRITAINDATA", resp.data);
        store.commit("SET_PRODUCTNOOFPAGE", resp.data.totalpage);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetThankYouPageData(store, params) {
    axios
      .get(`${url}wp/v2/thank_you_page_data`, { params })
      .then((resp) => {
        store.commit("SET_THANKYOUDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetCustomiseSvgfile(store, params) {
    axios
      .get(`${url}wp/v2/GetCustomiseSvgfile`, { params })
      .then((resp) => {
        console.log("12344", resp.data);
        store.commit("SET_SVGFILE", resp.data.defultsvgfile);
        store.commit("SET_COMPONENTDATA", resp.data.components);
        store.commit("SET_SPECIFICATIONDATA", resp.data.specification);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async CustomisationContactMail(store, params) {
    axios
      .post(`${url}wp/v2/CustomisationContactMail`, params)
      .then((resp) => {
        //console.log('DOWNLOADFILE ',resp.data);
        Toastr.success(resp.data.message);
        // router.push({ name: 'ThankYou' })
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async CustomisationEstimateMail(store, params) {
    axios
      .post(`${url}wp/v2/CustomisationEstimateMail`, params)
      .then((resp) => {
        //console.log('DOWNLOADFILE ',resp.data);
        Toastr.success(resp.data.message);
        // router.push({ name: 'ThankYou' })
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async getProductMegaMenu(store, params) {
    return new Promise(function () {
      axios
        .get(`${url}wp/v2/get_product_menu_list`, { params })
        .then((resp) => {
          store.commit("SET_PRODUCTMENUCONTENT", resp.data);
          // console.log(resp.data)
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },

  async GetProductWattage(store, params) {
    axios
      .post(`${url}wp/v2/GetProductWattage`, params)
      .then((resp) => {
        //console.log('DOWNLOADFILE ',resp.data);
        Toastr.success(resp.data.message);
        // router.push({ name: 'ThankYou' })
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async GetLightingDirection(store, params) {
    axios
      .get(`${url}wp/v2/GetLightingDirection`, { params })
      .then((resp) => {
        store.commit("SET_LIGHTINGDIRECTIONDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetProductVariantsStapes(store, params) {
    axios
      .post(`${url}wp/v2/GetProductVariantsStapes`, params)
      .then((resp) => {
        console.log(resp.data);
        store.commit("SET_PRODUCTVARIENTSTAPECONTENT", resp.data);
      })
      .catch((err) => {
        Toastr.error(err.response.data.message);
      });
  },

  async Setcustomizationstepdata(store, params) {
    let paramsdata = {
      parent_product_id: params.parent_product_id,
      slug: params.slug,
      filteroptions: {},
    };
    Object.keys(params.filteroptions).forEach((key) => {
      let options = params.filteroptions[key];
      Object.keys(options).forEach((key2) => {
        paramsdata.filteroptions[key2] = options[key2];
      });
    });
    console.log("finalsection", params);
    store.commit("SET_CUSTOMIZATIONSTEPDATA", paramsdata);
    if (params.redirect == "yes")
      router.push({ path: "customisation/" + params.slug });
  },

  async GetCustomisationHistory(store, params) {
    axios
      .get(`${url}wp/v2/GetUserCustomisationHistory`, { params })
      .then((resp) => {
        store.commit("SET_CUSTOMISATIONHISTORY", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetProductTaxonomidetails(store, params) {
    axios
      .get(`${url}wp/v2/getfiloptionsforprocat`, { params })
      .then((resp) => {
        console.log("taxonomidetails", resp.data);
        store.commit("SET_PRODUCTTAXONOMIDETAILS", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async FilterProductListBytaxonomy(store, params) {
    // console.log(params)
    axios
      .get(`${url}wp/v2/FilterProductListBytaxonomy`, { params })
      .then((resp) => {
        console.log(resp);
        store.commit("SET_PRODUCTNOOFPAGE", resp.data.totalpage);
        store.commit("SET_FILTERPRODUCTTYPELIST", resp.data.data);
        store.commit("SET_FPRODUCTLISTTYPE", resp.data.list_type);
        store.commit("SET_FCATELIST", resp.data.cate_list);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetProjectDetailsPdf(store, params) {
    axios
      .get(`${url}wp/v2/get_case_study_details_pdf`, { params })
      .then((resp) => {
        // store.commit('SET_VARIATION_PRODUCT_DATASHEET', resp.data);
        //    resp.data.defultsvgfile
        const link = document.createElement("a");
        link.href = resp.data.url;
        link.setAttribute("download", resp.data.filename);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetProducttemplatetype(store, params) {
    axios
      .get(`${url}wp/v2/GetProducttemplatetype`, { params })
      .then((resp) => {
        store.commit("SET_PODUCTTEMPLATETYPE", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async setDataInStore(store, params) {
    // console.log(params)
    store.commit("SET_HEADERSEARCHDATA", params);
  },

  async Getproductbannerdata(store, params) {
    axios
      .get(`${url}wp/v2/getproductbannerdata`, { params })
      .then((resp) => {
        store.commit("SET_PRODUCTBANNERDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetCeilingsFireSafetyData(store, params) {
    axios
      .get(`${url}wp/v2/i_joist_ceilingsfire_safety_page_data`, { params })
      .then((resp) => {
        store.commit("SET_CEILINGFIRESEFTYDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetManufacturingUseExportData(store, params) {
    axios
      .get(`${url}wp/v2/manufacturing_use_export_page_data`, { params })
      .then((resp) => {
        store.commit("SET_UAEEXPORTDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async GetCommercialIndustrialData(store, params) {
    axios
      .get(`${url}wp/v2/commercial_industrial_page_data`, { params })
      .then((resp) => {
        // console.log(resp.data)
        store.commit("SET_CMMERCIALINDUSTRIALDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async getLedGenDetails(store, params) {
    axios
      .get(`${url}wp/v2/get_lighting_design_leadgen_data`)
      .then((resp) => {
        // console.log(resp.data)
        store.commit("SET_LEDGENPAGEDATA", resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};

export default actions;
